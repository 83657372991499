import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedReq = req;
        if (req.url.startsWith(environment.apiUrl)) {
            const authToken = this.authService.getToken();
            if (authToken) {
                modifiedReq = req.clone({
                    headers: req.headers.set('Authorization', `${authToken}`)
                });
            }
        }
        return next.handle(modifiedReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 && !this.authService.refreshTokenInProgress) {
                    return this.handleUnauthorizedError(modifiedReq, next);
                }
                return throwError(() => error);
            })
        );
    }

    private handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authService.refreshToken()).pipe(switchMap((response: any) => {
            const newAuthToken = response.data.accessToken;
            this.authService.storeToken(newAuthToken);

            const modifiedReq = req.clone({
                headers: req.headers.set('Authorization', `${newAuthToken}`)
            });

            return next.handle(modifiedReq);
        }),
            catchError(error => {
                this.authService.logout();
                this._router.navigate(['/auth/login'])
                return throwError(() => error);
            })
        );
    }
}
