import { Component, HostBinding } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-animator',
    template: `<ng-content></ng-content>`,  // Content projection
    animations: [
        trigger('combinedSlideBlur', [
            state('void', style({
                transform: 'translateY(100%)',
                opacity: 0,
                filter: 'blur(10px)'
            })),
            transition(':enter', [
                animate('500ms cubic-bezier(0.25, 1.0, 0.5, 1.0)', style({
                    transform: 'translateY(0)',
                    opacity: 1,
                    filter: 'blur(0)'
                }))
            ]),
            transition(':leave', [
                animate('300ms ease-in-out', style({
                    transform: 'translateY(100%)',
                    opacity: 0,
                    filter: 'blur(10px)'
                }))
            ])
        ])
    ]
})
export class AnimatorComponent {
    @HostBinding('@combinedSlideBlur') animation = true;
}