import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExternalGuard, InternalGuard } from '../shared/guards/auth.guards';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule),
    canActivate: [ExternalGuard],
  },
  {
    path: '',
    loadChildren: () => import('../pages/internal/internal.module').then(m => m.InternalModule),
    canActivate: [InternalGuard],
  },
  {
    path: '**',
    redirectTo: '/auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
