import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../services';
import DefaultApiResponse from '../dtos/default-api-error';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';

@Injectable()
export class PostingInterceptor implements HttpInterceptor {
    constructor(private _alertService: AlertService, private _translate: TranslateService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (req.method == 'post')
        return next.handle(req).pipe(catchError(e => {
            let warnMessage = this._translate.instant(`GENERAL.ERRORS.${((e.name as string).toUpperCase())}`)
            !environment.production && console.log(e)
            this._alertService.warn(this._translate.instant(`GENERAL.ERRORS.TITLE`), warnMessage)
            if (e.status == 401)
                throw e;
            return of(new HttpResponse({
                body: DefaultApiResponse,
                status: DefaultApiResponse?.StatusCode! || 426 // or appropriate status code
            }))
        }));
        // return next.handle(req);
    }
}