

<block-ui [template]="blockTemplate">
    <app-animator>
        <router-outlet></router-outlet>
    </app-animator>
</block-ui>

<ng-template #blockTemplate>
    <lottie-player src="https://lottie.host/cfe2c626-bb04-47eb-838e-106ebea69f06/uYVZdu4EYh.json" background="transparent" speed="1" direction="1" mode="normal" loop autoplay></lottie-player>
</ng-template>
