import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { AuthService } from '../services';

// Guard for internal routes (requires authentication)
export const InternalGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.currentUser$.pipe(
        map(user => {
            if (user) {
                return true;
            } else {
                router.navigate(['auth']);
                return false;
            }
        }),
        catchError(() => {
            router.navigate(['auth']);
            return of(false);
        })
    );
};

export const ExternalGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.currentUser$.pipe(
        map(user => {
            if (user) {
                router.navigate(['/']);
                return false;
            } else {
                return true;
            }
        }),
        catchError(() => {
            return of(true);
        })
    );
};